import React from 'react'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import '../components/fonts.css'
import '../components/global.css'
import favicon from '../components/favicon.png'

import Menu from '../components/Menu'
import Footer from '../components/Footer'
import fastql from '../components/fastql.png'
import fastqlBg from '../components/fastql-bg.jpg'
import fastqlRender from '../components/fastql-render.jpg'

import wire1 from '../components/fastql-wire1.jpg'
import wire2 from '../components/fastql-wire2.jpg'
import wire3 from '../components/fastql-wire3.jpg'
import wire4 from '../components/fastql-wire4.jpg'

import shot1 from '../components/fastql-shot1.jpg'
import shot2 from '../components/fastql-shot2.jpg'
import shot3 from '../components/fastql-shot3.jpg'
import shot4 from '../components/fastql-shot4.jpg'

const Container = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`
const Hero = styled.div`
  position: relative;
  height: 90%;
  background-image: url(${fastqlBg});
  background-size: cover;
`
const Wrapper = styled.div`
  position: relative;
  top: 50%;
  transform: translateY(-60%);
  width: 80%;
  margin: 0 auto;
  text-align: center;
  color: white;
  h1,
  img,
  svg,
  a {
    position: relative;
    opacity: 0;
    animation: fade 1s ease-in forwards, slideUp 700ms ease-in-out forwards;
  }
  h1 {
    font-size: 1.9em;
    padding: 20px 0 0;
    font-family: merri-light;
    animation-delay: 120ms;
    line-height: 100%;
    @media (min-width: 1440px) {
      font-size: 2.4em;
    }
  }
  a {
    display: inline-block;
    color: white;
    margin: 0 auto;
    padding: 50px 0 0;
    font-size: 1.3em;
    font-family: merri-bold;
    animation-delay: 250ms;
    transition: all 130ms cubic-bezier(0.2, 0.8, 0.2, 1);
    &:hover {
      transform: scale(1.1);
    }
    @media (min-width: 1440px) {
      font-size: 1.8em;
    }
  }
  img {
    width: 100%;
    max-width: 450px;
    padding: 20px 0;
    @media (min-width: 1440px) {
      max-width: 625px;
    }
  }
`
const Brief = styled.div`
  display: flex;
  color: #374a66;
  padding: 10% 5% 5%;
  min-height: 600px;
  max-width: 900px;
  margin: 0 auto;
  @media (min-width: 1440px) {
    max-width: 1400px;
  }
  @media (max-width: 500px) {
    flex-wrap: wrap;
  }
  div {
    flex: 0 100%;
    @media (max-width: 500px) {
      flex: 0 100%;
      text-align: center;
    }
  }

  h1 {
    line-height: 120%;
    font-family: merri-bold;
    font-size: 2.2em;
    width: 90%;
    padding-bottom: 10px;
    @media (max-width: 500px) {
      width: 100%;
    }
    @media (min-width: 1440px) {
      font-size: 3em;
    }
  }
  h2 {
    padding: 0;
    font-size: 1.1em;
    @media (max-width: 500px) {
      flex: 0 100%;
      text-align: center;
    }
  }
  p {
    width: 80%;
    font-size: 1em;
    font-family: merri-light;
    @media (max-width: 500px) {
      width: 100%;
    }
  }
  .tech {
    display: flex;
    flex-wrap: wrap;
    width: 300px;
    margin-top: 40px;
    @media (max-width: 500px) {
      width: 100%;
      margin: 30px 0;
    }
    span {
      display: inline-block;
      border: 2px solid #c7d4e866;
      border-radius: 14px;
      text-align: center;
      flex: 0 46%;
      padding: 6px 0;
      margin: 4px;
      font-family: gotham-bold;
      text-transform: uppercase;
      font-size: 0.9em;
      color: #5e7699;
    }
  }
  .contrib {
    display: flex;
    align-items: center;
    padding: 8px 0;
    b {
      font-family: merri-bold;
      padding: 0 10px;
    }
    svg {
      width: 30px;
      margin: 0 10% 0 0;
    }
  }
  a {
    display: inline-block;
    background: #b7315f;
    color: white;
    border-radius: 12px;
    padding: 10px 20px;
    font-family: gotham-bold;
    text-transform: uppercase;
    font-size: 0.9em;
    margin: 40px 0;
    @media (max-width: 500px) {
    }
  }
  .contribBlock {
    padding: 0 30px;
    flex: 0 30%;
    @media (max-width: 500px) {
      flex: 0 100%;
      padding: 0;
    }
  }
`

const Shots = styled.div`
  width: 100%;
  margin: 0 auto;
  img.title {
    width: 100%;
    margin: 0 0 -10px 0;
  }
  .wires {
    display: flex;
    img {
      padding: 4px;
    }
  }
  .shots {
    display: flex;
    flex-wrap: wrap;
    img {
      flex: 0 50%;
    }
  }
`
export default () => (
  <Container>
    <Helmet>
      <meta charSet="utf-8" />
      <title>David D. Ortiz | Product Designer</title>
      <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
      <link
        rel="alternate"
        href="https://www.davidortiz.me/fastql/"
        hreflang="en-us"
      />
      <link rel="canonical" href="https://www.davidortiz.me/fastql/" />
      <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
      <meta
        name="description"
        content="Product Designer | UX, Front End & Deployment for Remote Startups."
      />
      <meta name="viewport" content="width=device-width, initial-scale=1" />

      <meta property="og:title" content="David D. Ortiz | Product Designer" />
      <meta property="og:site_name" content="Personal Portfolio 2019" />
      <meta
        property="og:description"
        content="Product Designer | UX, Front End & Deployment for Remote Startups."
      />
    </Helmet>
    <Menu />
    <Hero>
      <Wrapper>
        <img src={fastql} alt="FastQL" />
        <h1>Smart GraphQL caching and CDN</h1>
        <a
          href="https://www.fastql.io"
          target="_blank"
          rel="noopener noreferrer"
          alt="FastQL"
        >
          https://www.fastql.io
        </a>
      </Wrapper>
    </Hero>

    <Brief>
      <div>
        <h1>Landing Page & Integrated Admin Dashboard</h1>
        <p>
          Caching GraphQL queries on the edge is no easy task, which is why we
          built FastQL. The only CDN built specifically for GraphQL
          applications.
        </p>

        <p>
          FastQL’s founder came to me with a plan to build out the base landing
          page and admin dashboard needed to launch their first prototype.
          Together we brainstormed ideas on branding, general aesthetics and
          wireframed the interface. I designed our plan to spec and exported the
          source files for developer hand-off.
        </p>

        <a
          href="https://www.fastql.io"
          target="_blank"
          rel="noopener noreferrer"
        >
          View Live Website
        </a>
      </div>

      <div className="contribBlock">
        <h2>Personal Contributions:</h2>

        <span className="contrib">
          <svg
            width="41"
            height="43"
            viewBox="0 0 41 43"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M41 0C34.8771 1.65208 22.0747 17.6632 17.2761 34.1581C13.4365 29.7479 7.14671 28.7393 0 30.8892C5.05007 32.35 11.5968 37.3327 16.3475 43L25.532 40.5218C25.8565 26.0722 31.9753 13.091 41 0Z"
              fill="#B0476B"
            />
          </svg>
          Project Duration: <b> 2 weeks</b>
        </span>

        <span className="contrib">
          <svg
            width="41"
            height="43"
            viewBox="0 0 41 43"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M41 0C34.8771 1.65208 22.0747 17.6632 17.2761 34.1581C13.4365 29.7479 7.14671 28.7393 0 30.8892C5.05007 32.35 11.5968 37.3327 16.3475 43L25.532 40.5218C25.8565 26.0722 31.9753 13.091 41 0Z"
              fill="#B0476B"
            />
          </svg>
          Art Direction
        </span>

        <span className="contrib">
          <svg
            width="41"
            height="43"
            viewBox="0 0 41 43"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M41 0C34.8771 1.65208 22.0747 17.6632 17.2761 34.1581C13.4365 29.7479 7.14671 28.7393 0 30.8892C5.05007 32.35 11.5968 37.3327 16.3475 43L25.532 40.5218C25.8565 26.0722 31.9753 13.091 41 0Z"
              fill="#B0476B"
            />
          </svg>
          Wireframes & Mock Ups
        </span>

        <div className="tech">
          <h2>Technologies Used:</h2>

          <span>Figma</span>
          <span>Photoshop</span>
          <span>InvisionApp</span>
          <span>Sketch</span>
        </div>
      </div>
    </Brief>

    <Shots>
      <img className="title" src={fastqlRender} alt="FastQL" />

      <div className="wires">
        <img src={wire1} alt="Wireframe" />
        <img src={wire2} alt="Wireframe" />
        <img src={wire3} alt="Wireframe" />
        <img src={wire4} alt="Wireframe" />
      </div>

      <div className="shots">
        <img src={shot1} alt="Rendered Shot 1" />
        <img src={shot2} alt="Rendered Shot 2" />
        <img src={shot3} alt="Rendered Shot 3" />
        <img src={shot4} alt="Rendered Shot 4" />
      </div>
    </Shots>
    <Footer />
  </Container>
)
